import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { CreateEvent } from './components/CreateEvent';
import { ViewEvent } from './components/ViewEvent';
import { API_URL } from './Global';

class App extends Component {
  constructor(props) {
    super(props);

    this.setPlanId = this.setPlanId.bind(this);

    var planid = new URLSearchParams(window.location.search).get('planid');

    this.state = {
      PLAN_ID: planid,
      LoadingEvent: planid !== null,
      ValidPlanID: false,
      Plan: {}
    }
  }

  componentDidMount() {
    if (this.state.LoadingEvent) {
      this.checkForPlan(this.state.PLAN_ID);
    } else {
      this.setOriginURL();
    }
  }

  setOriginURL() {
    window.history.replaceState("data", "title", window.location.origin);
  }

  checkForPlan(planid) {
    this.setState({
      LoadingEvent: true
    });
    fetch(API_URL + "GetEvent/" + planid)
      .then(response => response.json())
      .then(result => {
        const foundplan = result.id > 0;
        if (!foundplan) { this.setOriginURL(); }
        this.setState({
          LoadingEvent: false,
          ValidPlanID: foundplan,
          Plan: result
        });
      });
  }

  setPlanId(value) {
    this.setState({
      PLAN_ID: value
    });
    this.checkForPlan(value);
  }

  render() {
    return (
      <Container className="my-3 py-3">
        {this.state.LoadingEvent ?
          <div>Loading....</div> :
          this.state.ValidPlanID ?
            <ViewEvent data={this.state.Plan} /> :
            <CreateEvent setPlanId={this.setPlanId} />}
      </Container>
    );
  }
}

export default App;
