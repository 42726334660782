import React, { Component } from 'react';
import { BarChart } from './BarChart';
import { Card, CardHeader, CardBody, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { API_URL } from '../Global';
import Cookies from 'universal-cookie';

export class ViewEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: "",
      Options: [],
      ChartData: {
        header: "Deltagere",
        data: [],
        labels: this.props.data.options.map((o) => o.text),
        colors: [],
        members: []
      },
      VoteID: 0,
      Choises: []
    }
  }

  componentDidMount() {
    this.checkForVote();
    this.getChartData();
  }

  getChartData() {
    fetch(API_URL + "GetChartData/" + this.props.data.planId)
      .then(response => response.json())
      .then(result => {
        var data = this.state.ChartData;
        data.data = result.numbers;
        data.members = result.names;
        data.colors = result.colors;
        this.setState({ ChartData: data });
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.Name.length > 0) {

      const fd = {
        EventId: this.props.data.id,
        Name: this.state.Name,
        UserAvailables: []
      };
      const select = document.getElementById("selector");
      for (var i = 0; i < select.length; i++) {
        var sel = select[i];
        if (sel.selected) {
          fd.UserAvailables.push({ OptionId: sel.value });
          sel.selected = false;
        }
      }

      fetch(API_URL + "AddUser", {
        method: "POST",
        body: JSON.stringify(fd),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.json()
        .then(result => {
          this.setVoteCookie(result);
          this.getChartData();
        }));

      this.setState({ Name: "" });
    }
  }

  handleInput(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleSelection(event) {
    event.preventDefault();
    event.target.selected = !event.target.selected;
    document.getElementById("selector").focus();
  }

  setVoteCookie(id) {
    const cookie = new Cookies();
    cookie.set(this.props.data.planId, id, { path: '/' });
    this.checkForVote();
  }

  checkForVote() {
    const cookie = new Cookies();
    const userid = cookie.get(this.props.data.planId)

    if (userid > 0) {
      fetch(API_URL + "GetUserChoises/" + this.props.data.planId + "/" + userid)
        .then(response => response.json()
          .then(result => {
            this.setState({ Choises: result });
          }));
    }
    this.setState({ VoteID: userid });
  }

  deleteUser() {
    const cookie = new Cookies();
    cookie.remove(this.props.data.planId, { path: "/" });
    this.setState({VoteID: 0});
    fetch(API_URL + "DeleteUser/" + this.props.data.planId + "/" + this.state.VoteID, {
      method: "POST",
      body: "",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.json()
      .then(result => {
        this.getChartData();
      }));
  }

  render() {
    return (
      <div>
        <Card>
          <CardHeader>
            {this.props.data.headerText}
          </CardHeader>
          <CardBody>
            <BarChart data={this.state.ChartData} />
          </CardBody>
        </Card>
        {this.state.VoteID > 0 ?
          <div>
            <Card className="mt-2">
              <CardHeader>
                Tak for din deltagelse (Her er dit/dine valg!)
            </CardHeader>
              <CardBody className="py-1">
                {this.state.Choises.map((choise, i) => <div key={i} className="col px-0 py-1">{choise}</div>)}
              </CardBody>
            </Card>
            <Button className="col mt-2" color="secondary" onClick={() => this.deleteUser()}>Andet valg</Button>
          </div> :
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <InputGroup className="mt-2">
              <InputGroupAddon addonType="prepend">{this.props.data.nameText}</InputGroupAddon>
              <Input value={this.state.Name} name="Name" onChange={(e) => this.handleInput(e)} />
            </InputGroup>
            <Card className="mt-2">
              <CardHeader>{this.props.data.choiseText}</CardHeader>
              <CardBody className="p-0">
                <Input type="select" id="selector" multiple size={this.props.data.options.length.toString()} style={{ overflow: "hidden" }}>
                  {this.props.data.options.map((option, i) =>
                    <option key={i} onMouseDown={(e) => this.handleSelection(e)} value={option.id}>{option.text}</option>
                  )}
                </Input>
              </CardBody>
            </Card>
            <Button className="col mt-2" color="primary" disabled={this.state.Name.length > 0 ? false : true}>Gem valg!</Button>
          </form>
        }
      </div>
    );
  }
}
