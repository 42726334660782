import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

export class BarChart extends Component {
    render() {
        const members = this.props.data.members;

        const data = {
            datasets: [{
                data: this.props.data.data,
                backgroundColor: this.props.data.colors,
                // borderColor: '#333',
                label: this.props.data.header
            }],
            labels: this.props.data.labels,
        };

        const options = {
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    fontColor: '#000',
                    fontSize: 14,

                },
                display: false
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: "#000",
                        beginAtZero: true,
                        // callback: () => { return "davs" }
                    },
                    gridLines: {
                        // color: "#FFF",
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: "#000",
                        beginAtZero: true,
                        // stepSize: 2
                    },
                    gridLines: {
                        // color: "#FFF",
                        display: false
                    }
                }]
            },
            tooltips: {
                callbacks: {
                   title: function(t, d) {
                      return members[t[0].index];
                   }
                }
            }
        };

        return (
            <div >
                {/* <Doughnut data={data} options={options} /> */}
                <Bar data={data} options={options} />
            </div>
        );
    }
}