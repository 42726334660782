import React, { Component } from 'react';
import { API_URL } from '../Global';
import { FormGroup, Label, Input, Form, InputGroup, InputGroupAddon, Button } from 'reactstrap';

export class CreateEvent extends Component {
    constructor(props) {
        super(props);
        this.readyToSubmit = this.readyToSubmit.bind(this);

        this.state = {
            EventName: "",
            NameText: "",
            ChoiseText: "",
            Options: [""]
        };
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleOptions(event, i) {
        var Options = this.state.Options;
        Options[i] = event.target.value;

        var empty = [];
        for (var x = 0; x < Options.length; x++) {
            if (Options[x] === "") {
                empty.push(x);
            }
        }

        for (var y = empty.length - 1; y >= 0; y--) {
            Options.splice(empty[y], 1);
        }

        Options.push("");

        this.setState({ Options: Options });
    }

    handleSubmit(e) {
        e.preventDefault();

        var options = this.state.Options;
        var sendoptions = [];
        for (var x = 0; x < options.length - 1; x++) {
            sendoptions.push({ Text: options[x] });
        }

        const fd = {
            HeaderText: this.state.EventName,
            NameText: this.state.NameText,
            ChoiseText: this.state.ChoiseText,
            Options: sendoptions
        };

        fetch(API_URL + "createevent", {
            method: "POST",
            body: JSON.stringify(fd),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => response.json()
            .then(result => {
                window.history.replaceState("data", "title", window.location.origin + "?planid=" + result);
                this.props.setPlanId(result);
            }));
    }

    readyToSubmit() {
        const options = this.state.Options.length < 2;
        const eventname = this.state.EventName.length < 1;
        const nametext = this.state.NameText.length < 1;
        const choise = this.state.ChoiseText.length < 1;

        return eventname || nametext || choise || options;
    }

    render() {
        return (
            <div>
                <h1>
                    Opret Event
                </h1>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <FormGroup>
                        <Label for="eventname">Event navn</Label>
                        <Input id="eventname" name="EventName" value={this.state.EventName} onChange={(e) => this.handleInput(e)} placeholder="Indtast event tekst" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nametext">Deltager text</Label>
                        <Input id="nametext" name="NameText" value={this.state.NameText} onChange={(e) => this.handleInput(e)} placeholder="Indtast deltager tekst" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="choisetext">Tekst til valgmuligheder</Label>
                        <Input id="choisetext" name="ChoiseText" value={this.state.ChoiseText} onChange={(e) => this.handleInput(e)} placeholder="Indtast valgmulighedstekst" />
                    </FormGroup>
                    <Label for="options">Valgmuligheder</Label>
                    {this.state.Options.map((o, i) =>
                        <InputGroup key={i}>
                            <InputGroupAddon className="mb-1" addonType="prepend">{(i + 1).toString() + "."}</InputGroupAddon>
                            <Input className="mb-1" value={this.state.Options[i]} onChange={(e) => { this.handleOptions(e, i) }} placeholder="Indtast valgmulighed" />
                        </InputGroup>
                    )}
                    <Button className="col mt-4" type="submit" color="primary" disabled={this.readyToSubmit()} >Opret Event!</Button>
                </Form>
            </div>
        );
    }
}